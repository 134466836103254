import config from '@config'
import { PageData, PageMetadata } from '@hooks/usePageMetadata'
import dashboardFeed from '@utils/feature-flag-dashboard-feed'
import offerPageFeed from '@utils/offer-page-feed'
import { defaultConfig as queryConfig } from '@utils/react-query'
import { GetServerSideProps } from 'next'
import { FunctionComponent } from 'react'
import {
  dehydrate,
  DehydratedState,
  QueryClient,
  useQueryClient,
} from '@tanstack/react-query'
import {
  fetchPage,
  fetchSkeleton,
  getFullPageUrl,
  isValidPageMetadata,
  isValidSkeletonData,
  getRedirectToRSSInfo,
  ValidSkeletonData,
  getRSSContentInfo,
  RSSPathType,
  isBlickPlusOfferPage,
  hasImmersiveHero,
} from '@utils/cook'
import JSONRenderer from '@components/JSONRenderer'
import { getKropkaConfigObject } from '@utils/kropka'
import {
  RssFeed,
  transformDataforInxmail,
  transformDataforRSS,
} from '@utils/rss'
import { fetchPlaylist, getIsPlaylistResource } from '@utils/playlist'
import {
  INITIAL_DIMENSIONS,
  INITIAL_VIEWPORT_TYPE,
  INITIAL_VIEWPORT_ORIENTATION,
} from '@hooks/useViewport/utils'
import {
  fetchCueLive,
  fetchCueLiveSchemaData,
  fetchCueLiveTeaser,
} from '@utils/cueLive'
import {
  getSportsStandingsData,
  isValidSportsTable,
  composeRequestUrl as sportsStandingsComposeRequestUrl,
} from '@hooks/useSportsStandings'
import {
  composeRequestUrl as sportsEventComposeRequestUrl,
  getSportsEventData,
} from '@hooks/useSportsEvent'

export interface CatchAllPageServerProps {
  isError: boolean
  isBlickPlusOfferPage: boolean
  dehydratedQueryState: DehydratedState
  documentProps?: {
    isSwissGeolocation: boolean
    disableThirdPartyScripts: boolean
    kropkaConfig: string
  }
}

const {
  backend: {
    deploymentEnv,
    versionedApiUrl,
    skeletonUrl,
    blickReleaseVersion,
    apiEnvironment,
  },
  abTest: { dashboardPath },
  ads: {
    sdk: {
      fallbackEnvironment: atmConfigFileFallbackEnv,
      fallbackVersion: atmConfigFileFallbackVersion,
    },
  },
  subscriptions: { offerPagePath },
} = config

const CatchAllPage: FunctionComponent = () => {
  const queryClient = useQueryClient()

  const pageData = queryClient.getQueryData<PageData>(['page'])

  return <JSONRenderer>{pageData?.children ?? []}</JSONRenderer>
}

export default CatchAllPage

export const getServerSideProps: GetServerSideProps<
  CatchAllPageServerProps
> = async (context) => {
  const queryClient = new QueryClient(queryConfig)

  const fullPageUrl = getFullPageUrl(context.query.pageUrl ?? '')
  const token = (context.query.token ?? '') as string
  const pathUrlParam = context.query.path
  const isTeaserPreviewPage = context.query.layout === 'teaser-preview'
  const hasToken = !!context.query.token
  const isOfferPage = fullPageUrl === offerPagePath

  const { shouldRedirect, redirectPath } = getRedirectToRSSInfo(
    context.req.url ?? ''
  )

  if (shouldRedirect) {
    return {
      redirect: {
        destination: redirectPath,
        permanent: false,
      },
    }
  }

  const { shouldServeRSSCONTENT, rssPath, rssRequestUrl } = getRSSContentInfo(
    context.req.url ?? ''
  )

  if (shouldServeRSSCONTENT) {
    // Fetch the RSS Data
    const pageDataForRss = (
      await fetchPage({
        pageUrl: rssRequestUrl,
        token,
        pathUrlParam: '',
        foxCookies: {},
        isTeaserPreviewPage: false,
      })
    ).jsonFeed

    if (!pageDataForRss || Object.keys(pageDataForRss).length === 0) {
      return {
        notFound: true,
      }
    }

    const feedRSS = (['bplus_feed', 'bplus_feed_mixed'] as string[]).includes(
      rssPath as string
    )
      ? transformDataforInxmail(pageDataForRss as unknown as RssFeed)
      : transformDataforRSS({
          data: pageDataForRss,
          feedType: rssPath as RSSPathType,
        })

    context.res.setHeader('Content-Type', 'text/xml')
    context.res.write(feedRSS)
    context.res.end()

    return {
      props: {} as CatchAllPageServerProps,
    }
  }

  const isServerRendered = !context.req.url?.startsWith('/_next/data/')

  const {
    ADM_TAG_MANAGER_ENV,
    ADM_TAG_MANAGER_VERSION,
    au_seg,
    au_seg_beta,
    au_seg_sport,
    ea_uuid,
  }: {
    ADM_TAG_MANAGER_ENV?: string
    ADM_TAG_MANAGER_VERSION?: string
    au_seg?: string
    au_seg_beta?: string
    au_seg_sport?: string
    ea_uuid?: string
  } = context.req.cookies

  let isSwissGeolocation = true
  let disableThirdPartyScripts = false

  if (isServerRendered) {
    //! The x-bl-g header can either be:
    //! - not provided: this means the request is not coming from Switzerland
    //! - provided and set to false: this means the request is coming from Switzerland
    //! Because the header's value is parsed as a string, we treat any non empty header value
    //! as request coming from Switzerland, since otherwise the header wouldn't be present at all.
    //! The x-bl-g header is only supported where AKAMAI is supported,
    //! on production and staging environments, so for local and PR environments
    //! we set the isSwissGeolocation to true.
    const xblgHeader = context.req.headers['x-bl-g']

    const forceGeolocationSetSwiss =
      process.env.NEXT_PUBLIC_FORCE_GEOLOCATION_SET_SWISS

    if (
      forceGeolocationSetSwiss &&
      ['true', 'false'].includes(forceGeolocationSetSwiss)
    ) {
      isSwissGeolocation = forceGeolocationSetSwiss === 'true'
    } else {
      isSwissGeolocation =
        !['test', 'stg', 'prod'].includes(deploymentEnv) || !!xblgHeader
    }

    // Send the header as a response header back to the server (for testing purposes)
    if (xblgHeader) {
      context.res.setHeader('x-bl-g-check', xblgHeader)
    }

    queryClient.setQueryData(['isSwissGeolocation'], isSwissGeolocation)
    queryClient.setQueryData(['viewportType'], INITIAL_VIEWPORT_TYPE)
    queryClient.setQueryData(['clientViewportType'], 'server')
    queryClient.setQueryData(
      ['viewportOrientation'],
      INITIAL_VIEWPORT_ORIENTATION
    )
    queryClient.setQueryData(['viewportDimensions'], INITIAL_DIMENSIONS)
  }

  await Promise.allSettled([
    isServerRendered
      ? queryClient.prefetchQuery({
          queryKey: ['skeleton'],
          queryFn: fetchSkeleton,
        })
      : Promise.resolve(true),
    queryClient.prefetchQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['page'],
      queryFn: async () => {
        if (fullPageUrl === dashboardPath) {
          return dashboardFeed
        } else if (isOfferPage) {
          return offerPageFeed
        } else {
          const pageRequestData = await fetchPage({
            pageUrl: fullPageUrl,
            token,
            pathUrlParam: (pathUrlParam as string) ?? '',
            foxCookies: { au_seg, au_seg_beta, au_seg_sport, ea_uuid },
            isTeaserPreviewPage,
          })

          //! It is very important to use this header value if it exists
          //! for geoblocking reasons (both for Server & Client side navigation).
          const geoCDNHeader = pageRequestData.headers.get('x-bl-geo-cdn')
          if (geoCDNHeader) {
            context.res.setHeader('x-bl-geo-cdn', geoCDNHeader)
          }

          //! Forwards the x-bl-plus header back to the CDN
          //! used to block Blick Plus content on certain bots.
          const blickPlusHeader = pageRequestData.headers.get('x-bl-plus')
          if (blickPlusHeader) {
            context.res.setHeader('x-bl-plus', blickPlusHeader)
          }

          return pageRequestData.jsonFeed
        }
      },
    }),
  ])

  queryClient.setQueryData(['debug', 'api', 'environment'], apiEnvironment)
  queryClient.setQueryData(['debug', 'api', 'endpoint'], versionedApiUrl)
  queryClient.setQueryData(['debug', 'api', 'skeletonUrl'], skeletonUrl)
  queryClient.setQueryData(['debug', 'server', 'version'], blickReleaseVersion)
  queryClient.setQueryData(['casting'], false)
  queryClient.setQueryData(['has-token'], hasToken)
  queryClient.setQueryData(['is-in-teaser-preview-page'], isTeaserPreviewPage)
  queryClient.setQueryData(['animated-previews-list'], [])
  queryClient.setQueryData(['isBlickPlusOfferShown'], false)
  queryClient.setQueryData(['pianoTemplateParams'], null)
  queryClient.setQueryData(['pianoCheckoutParams'], null)
  queryClient.setQueryData(['isGiftedArticle'], false)
  queryClient.setQueryData(['force-center-content'], false)
  queryClient.setQueryData(['full-screen-portal-rendered-node-ref'], null)
  queryClient.setQueryData(['modal-overlay-portal-rendered-node-ref'], null)
  queryClient.setQueryData(['full-screen-image-shown'], false)
  queryClient.setQueryData(['fast-lane-bite-bookmark-fetched'], false)
  queryClient.setQueryData(['aureusOfferTracked'], {})
  queryClient.setQueryData(['isServerRendered'], isServerRendered)
  queryClient.setQueryData(['areAdSlotsLoaded'], false)
  queryClient.setQueryData(['RiAdTagManagerInitCode'], '')
  queryClient.setQueryData(['burgerMenuVisibility'], {
    isVisible: false,
    resetScrollPosition: true,
  })
  queryClient.setQueryData(['userMenuVisible'], false)
  queryClient.setQueryData(['chatbotVisible'], false)
  queryClient.setQueryData(['is-header-second-row-collapsed'], null)
  queryClient.setQueryData(['articleSchemaData'], {
    articleBody: [],
    description: '',
  })

  if (context.query.utm_source) {
    queryClient.setQueryData(['utmSource'], context.query.utm_source)
  } else {
    queryClient.setQueryData(['utmSource'], '')
  }

  if (context.query.utm_medium) {
    queryClient.setQueryData(['utmMedium'], context.query.utm_medium)
  } else {
    queryClient.setQueryData(['utmMedium'], '')
  }

  if (context.query.utm_campaign) {
    queryClient.setQueryData(['utmCampaign'], context.query.utm_campaign)
  } else {
    queryClient.setQueryData(['utmCampaign'], '')
  }

  if (isServerRendered) {
    const skeletonData = queryClient.getQueryData<ValidSkeletonData | unknown>([
      'skeleton',
    ])

    if (!isValidSkeletonData(skeletonData)) {
      return {
        notFound: true,
      }
    }

    queryClient.setQueryData(
      ['atmEnv'],
      ADM_TAG_MANAGER_ENV ||
        skeletonData?.skeletonData?.[0]?.['adSDK']?.['environment'] ||
        atmConfigFileFallbackEnv
    )
    queryClient.setQueryData(
      ['atmVersion'],
      ADM_TAG_MANAGER_VERSION ||
        skeletonData?.skeletonData?.[0]?.['adSDK']?.['version'] ||
        atmConfigFileFallbackVersion
    )

    queryClient.setQueryData(
      ['navigationSkeleton'],
      skeletonData.skeletonData[0].navigation
    )
    queryClient.setQueryData(
      ['footerSkeleton'],
      skeletonData.skeletonData[0].footer
    )
    queryClient.setQueryData(
      ['videoSkeleton'],
      skeletonData.skeletonData[0].video
    )
    queryClient.setQueryData(
      ['blickPlusSkeleton'],
      skeletonData.skeletonData[0].blickPlus
    )

    //! We never actually use skeleton as is,
    //! so we remove it from the cache.
    queryClient.removeQueries({ queryKey: ['skeleton'], exact: true })

    disableThirdPartyScripts =
      !!skeletonData.skeletonData[0].disableThirdPartyScripts

    queryClient.setQueryData(
      ['disableThirdPartyScripts'],
      disableThirdPartyScripts
    )

    queryClient.setQueryData(['body-scroll'], {
      isBodyScrollLocked: false,
      scrollPosition: 0,
    })
  }

  const pageData = queryClient.getQueryData<any>(['page'])

  if (pageData?.statusCode === 301 && !!pageData?.location) {
    return {
      redirect: {
        destination: (pageData as any).location,
        permanent: true,
      },
    }
  }

  if (pageData?.statusCode === 404) {
    return {
      notFound: true,
    }
  }

  if (pageData?.error) {
    return {
      notFound: true,
    }
  }

  if (!isValidPageMetadata(pageData?.metadata)) {
    return {
      notFound: true,
    }
  }

  if (isServerRendered) {
    queryClient.setQueryData(
      ['is-immersive-header-active'],
      hasImmersiveHero(pageData?.metadata)
    )
  }

  //! Playlist resources are not actual articles, so in case
  //! someone visits them directly we return 404 Not Found.
  if (getIsPlaylistResource(pageData)) {
    return {
      notFound: true,
    }
  }

  if (pageData?.childrenBeta) {
    delete pageData.childrenBeta
  }

  const cueLiveIds = pageData?.metadata?.cueLiveIds

  if (Array.isArray(cueLiveIds)) {
    await Promise.allSettled(
      (cueLiveIds as Required<PageMetadata>['cueLiveIds']).reduce(
        (acc, cueLiveId) => {
          //! Extra guard for empty cueLiveIds; to be removed once the API response is fixed.
          if (cueLiveId) {
            acc.push(
              queryClient.prefetchQuery({
                // eslint-disable-next-line @tanstack/query/exhaustive-deps
                queryKey: ['cue-live', cueLiveId, 'refreshing'],
                queryFn: () => fetchCueLive(cueLiveId, undefined, fullPageUrl),
              }),
              queryClient.prefetchQuery({
                queryKey: ['cue-live', cueLiveId, 'schemaOrg'],
                queryFn: () => fetchCueLiveSchemaData(cueLiveId),
              })
            )
          }

          return acc
        },
        [] as Promise<void>[]
      )
    )
  }

  const cueLiveTeaserIds = pageData?.metadata?.cueLiveTeaserIds

  if (Array.isArray(cueLiveTeaserIds)) {
    await Promise.allSettled(
      (cueLiveTeaserIds as Required<PageMetadata>['cueLiveTeaserIds']).reduce(
        (acc, cueLiveTeaserId) => {
          //! Extra guard for empty cueLiveIds; to be removed once the API response is fixed.
          if (cueLiveTeaserId) {
            acc.push(
              queryClient.prefetchQuery({
                queryKey: ['cue-live-teaser', cueLiveTeaserId],
                queryFn: () => fetchCueLiveTeaser(cueLiveTeaserId),
              })
            )
          }

          return acc
        },
        [] as Promise<void>[]
      )
    )
  }

  const sportsTableIds = pageData?.metadata?.sportsTableIds

  if (Array.isArray(sportsTableIds)) {
    await Promise.allSettled(
      (sportsTableIds as Required<PageMetadata>['sportsTableIds']).reduce(
        (acc, sportsTableId) => {
          const { currentSeasonId: seasonId, typeOfSport } = sportsTableId

          acc.push(
            queryClient.prefetchQuery({
              queryKey: ['sportsStandings', typeOfSport, seasonId],
              queryFn: () =>
                getSportsStandingsData(
                  sportsStandingsComposeRequestUrl({ typeOfSport, seasonId })
                ),
            })
          )

          return acc
        },
        [] as Promise<void>[]
      )
    )

    //! Because of the way the sports standings are fetched and validated,
    //! we'd need to remove any invalid data from the server-side cache.
    queryClient
      .getQueriesData({ queryKey: ['sportsStandings'] })
      .forEach(([queryKey, queryData]) => {
        const isValid =
          isValidSportsTable(queryData) &&
          queryData.sportsTableType === queryKey[1] &&
          queryData.sportsTableId === queryKey[2]

        if (!isValid) {
          queryClient.removeQueries({ queryKey, exact: true })
        }
      })
  }

  const sportsEventIds = pageData?.metadata?.sportsEventIds

  if (Array.isArray(sportsEventIds)) {
    await Promise.allSettled(
      (sportsEventIds as Required<PageMetadata>['sportsEventIds']).reduce(
        (acc, sportsEventId) => {
          const { matchId, typeOfSport } = sportsEventId

          acc.push(
            queryClient.prefetchQuery({
              queryKey: ['sportsEvent', typeOfSport, matchId],
              queryFn: () =>
                getSportsEventData({
                  expectedTypeOfSport: typeOfSport,
                  expectedMatchId: matchId,
                })(sportsEventComposeRequestUrl({ matchId, typeOfSport })),
            })
          )

          return acc
        },
        [] as Promise<void>[]
      )
    )
  }

  const playlistSrc = pageData?.metadata?.playlistSrc

  if (playlistSrc) {
    if (isServerRendered) {
      await queryClient.prefetchQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['playlist-videos', playlistSrc],
        queryFn: () =>
          fetchPlaylist({
            playlistSrc,
            token,
          }),
      })
    }
  }

  let documentProps

  if (isServerRendered) {
    documentProps = {
      isSwissGeolocation: queryClient.getQueryData<boolean>([
        'isSwissGeolocation',
      ]) as boolean,
      disableThirdPartyScripts,
      kropkaConfig: JSON.stringify(
        getKropkaConfigObject({
          metadata: pageData.metadata,
          isSwissGeolocation: queryClient.getQueryData<boolean>([
            'isSwissGeolocation',
          ]) as boolean,
        })
      ),
    }
  }

  return {
    props: {
      isError: false,
      isBlickPlusOfferPage: isBlickPlusOfferPage(fullPageUrl),
      dehydratedQueryState: dehydrate(queryClient),
      //! "documentProps" is meant to be used *only* by _document.tsx.
      //! For anything else use react-query.
      ...(documentProps ? { documentProps } : {}),
    },
  }
}
